.scrollbar {
    margin-left: 30px;
    float: left;
    height: 600px;
    width: 1200px;
    background-color:black;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 25px; }
    
    .scrollbar-pink::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5;
    border-radius: 10px; }
    
    .scrollbar-pink::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5; }
    
    .scrollbar-pink::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #ec407a; }
    
    .scrollbar-indigo {
      scrollbar-color: #3f51b5 #f5f5f5;
    }
    
    .scrollbar-indigo::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5;
    border-radius: 10px; }
    
    .scrollbar-indigo::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5; }
    
    .scrollbar-indigo::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #3f51b5; }
    
    .scrollbar-black {
      scrollbar-color: #000 #f5f5f5;
      background-color: #000;
    }
    
    .scrollbar-black::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5;
    border-radius: 10px; }
    
    .scrollbar-black::-webkit-scrollbar {
    width: 12px;
    background-color: black; }
    
    .scrollbar-black::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #000; }
    
    .scrollbar-lady-lips {
      scrollbar-color: #ff9a9e #f5f5f5;
    }
    
    .scrollbar-lady-lips::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5;
    border-radius: 10px; }
    
    .scrollbar-lady-lips::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5; }
    
    .scrollbar-lady-lips::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image: -webkit-gradient(linear, left bottom, left top, from(#ff9a9e), color-stop(99%, #fecfef),
    to(#fecfef));
    background-image: -webkit-linear-gradient(bottom, #ff9a9e 0%, #fecfef 99%, #fecfef 100%);
    background-image: linear-gradient(to top, #ff9a9e 0%, #fecfef 99%, #fecfef 100%); }
    
    .scrollbar-near-moon {
      scrollbar-color: #b490ca #f5f5f5;
    }
    
    .scrollbar-near-moon::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5;
    border-radius: 10px; }
    
    .scrollbar-near-moon::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5; }
    
    .scrollbar-near-moon::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image: -webkit-gradient(linear, left bottom, left top, from(#5ee7df), to(#b490ca));
    background-image: -webkit-linear-gradient(bottom, #5ee7df 0%, #b490ca 100%);
    background-image: linear-gradient(to top, #5ee7df 0%, #b490ca 100%); }
    
    .bordered-pink::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border: 1px solid #ec407a; }
    
    .bordered-pink::-webkit-scrollbar-thumb {
    -webkit-box-shadow: none; }
    
    .bordered-indigo::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border: 1px solid #3f51b5; }
    
    .bordered-indigo::-webkit-scrollbar-thumb {
    -webkit-box-shadow: none; }
    
    .bordered-black::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border: 1px solid #000; }
    
    .bordered-black::-webkit-scrollbar-thumb {
    -webkit-box-shadow: none; }
    
    .square::-webkit-scrollbar-track {
    border-radius: 0 !important; }
    
    .square::-webkit-scrollbar-thumb {
    border-radius: 0 !important; }
    
    .thin::-webkit-scrollbar {
    width: 2px; }